<template>
  <div class="form-item">
    <div class="left-wrap flex-center">
      <slot></slot>
    </div>
    <div class="input-main">
      <div class="input-wrap flex-center">
        <input
          :value="value"
          :type="type"
          :readonly="readonly"
          :placeholder="placeholder"
          :maxlength="maxlength"
          class="input-txt"
          @input="$emit('input', $event.target.value)"
          @click="$emit('click')"
        />
      </div>
      <div class="right-wrap flex-center">
        <slot name="right">
          <van-icon
            v-if="clearable && value && value.length > 0"
            class="clear-icon"
            name="clear"
            size="20"
            color="#999"
            @click="$emit('input', '')"
          />
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable vue/require-default-prop */
export default {
  name: "LoginField",
  props: {
    value: {
      type: String,
    },
    type: {
      type: String,
      default: "text",
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
    },
    placeholder: {
      type: String,
      default: "",
    },
    maxlength: {
      type: Number,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="less" scoped>
.form-item {
  display: flex;
  align-items: center;
  padding: 10px 0;
  .left-wrap {
    margin-right: 10px;
  }
  .input-main {
    display: flex;
    flex-grow: 1;
    align-items: center;
    border-bottom: 1px solid #ddd;

    &:hover {
      border-bottom: 1px solid #4889f7;
    }

    .input-wrap {
      flex-grow: 1;
      flex-shrink: 1;
      .input-txt {
        box-sizing: border-box;
        width: 100%;
        height: 50px;
        font-size: 16px;
        line-height: 50px;
        border: none;
        &::placeholder {
          font-size: 14px;
          color: #aaa;
        }
      }
    }
    .right-wrap {
      padding-left: 2px;
    }
  }
}
</style>
