<template>
  <div class="login-page">
    <div class="page-title">
      <span v-if="loginMode === 1">手机登录</span>
      <span v-if="loginMode === 2">密码登录</span>
    </div>
    <div class="step">
      <template v-if="loginMode === 1">
        <Field
          v-model="phone"
          :maxlength="11"
          placeholder="请输入手机号"
          :clearable="true"
          type="tel"
        >
          <van-icon :name="phoneIcon" size="20" />
        </Field>
        <Field
          v-model="form.verficationCode"
          :maxlength="6"
          placeholder="请输入短信验证码"
          type="tel"
        >
          <van-icon :name="safeIcon" size="20" />
          <span
            slot="right"
            class="verify-code"
            :class="{ 'verify-code-count': isCountDown }"
            @click="sendCode"
          >
            {{ isCountDown ? `${countDownNum}s重新发送` : "发送验证码" }}
          </span>
        </Field>
      </template>

      <template v-if="loginMode === 2">
        <Field
          v-model="form.userCode"
          placeholder="请输入账号"
          :clearable="true"
        >
          <van-icon :name="userIcon" size="20" />
        </Field>
        <Field
          v-model="form.password"
          :maxlength="6"
          placeholder="请输入密码"
          :type="showPassword ? 'text' : 'password'"
        >
          <van-icon :name="safeIcon" size="20" />
          <van-icon
            slot="right"
            size="20"
            color="#999"
            :name="showPassword ? 'eye-o' : 'closed-eye'"
            @click="showPassword = !showPassword"
          />
        </Field>
      </template>

      <div class="login-mode">
        <span v-if="loginMode === 1" @click="switchLoginMode(2)">
          使用密码登录
        </span>
        <span v-if="loginMode === 2" @click="switchLoginMode(1)">
          使用手机登录
        </span>
      </div>

      <template v-if="needRegister">
        <van-divider>首次登录，请填写相关信息</van-divider>
        <Field
          v-model="form.userName"
          :maxlength="30"
          type="text"
          placeholder="请填写昵称"
          clearable
        >
          <van-icon :name="userIcon" size="20" />
        </Field>

        <Field
          v-model="form.city"
          type="text"
          placeholder="请选择城市"
          @click="onClickCityInput"
        >
          <van-icon :name="localIcon" size="20" />
        </Field>
      </template>

      <div class="btn-wrap">
        <van-button
          round
          type="info"
          block
          :disabled="canSubmit"
          @click="login"
        >
          登录
        </van-button>
      </div>
    </div>

    <van-popup v-model="showCitySelector" round position="bottom">
      <van-cascader
        v-model="form.cityId"
        title="请选择城市"
        :options="cityOptions"
        @close="showCitySelector = false"
        @finish="onFinishCity"
      />
    </van-popup>
  </div>
</template>

<script>
import {
  queryVerificationCode,
  userLogin,
  userLoginByPassword,
  queryCityList,
  LoginTypeEnum,
  redirectUrlMap,
  DEFAULT_CALLBACK_URL,
} from "@/apis";
import { getCookie } from "@/tools/cookie";
import { getUrlParams } from "../shared";
import { Toast } from "vant";
import Field from "./components/field.vue";

const COUNT_DOWN_NUM = 60;

const PHONE_REG = /^1\d{10}$/;
export default {
  name: "Login",
  components: {
    Field,
  },
  data() {
    return {
      phoneIcon: require("@/assets/phone.png"),
      localIcon: require("@/assets/local.png"),
      safeIcon: require("@/assets/safe.png"),
      userIcon: require("@/assets/user.png"),
      urlParams: {},
      phone: null,
      channelCode: null,
      loginType: LoginTypeEnum.TASK_COLLECT,
      form: {
        verficationCode: null,
        userName: null,
        city: "",
        cityId: null,
        userCode: "",
        password: "",
      },
      needRegister: false,
      isCountDown: false,
      countDownNum: COUNT_DOWN_NUM,
      startTime: null,
      loading: false,
      needCompleteInfo: false,
      showCitySelector: false,
      cityOptions: [],
      columns: [],

      // 登录模式, 1-手机登录, 2-密码登录
      loginMode: 1,
      // 是否明文展示密码
      showPassword: false,
    };
  },
  computed: {
    checkPhone() {
      if (this.phone && PHONE_REG.test(this.phone.trim())) {
        return true;
      }
      return false;
    },
    canSubmit() {
      let disabled = false;

      switch (this.loginMode) {
        case 1:
          if (
            !this.checkPhone ||
            !this.form.verficationCode?.trim() ||
            (this.needRegister && !this.form.userName?.trim()) ||
            (this.needRegister && !this.form.cityId)
          ) {
            disabled = true;
          }
          break;

        case 2:
          if (!this.form.userCode?.trim() || !this.form.password?.trim()) {
            disabled = true;
          }
          break;

        default:
      }

      return disabled;
    },
    redirectUrl() {
      return redirectUrlMap[this.loginType] || DEFAULT_CALLBACK_URL;
    },
  },
  created() {
    this.urlParams = getUrlParams(this.$route.query) || {};
    const { channelCode, loginType = LoginTypeEnum.TASK_COLLECT } =
      this.urlParams;
    this.loginType = loginType;
    if (!channelCode) {
      Toast("渠道信息不存在，请重新扫码登录");
    } else {
      this.channelCode = channelCode + "";
    }
    this.getCityOptions();
  },
  methods: {
    async sendCode() {
      if (this.isCountDown) return;
      if (this.loading) return;
      if (this.checkPhone) {
        this.loading = true;
        try {
          const res = await queryVerificationCode({
            phone: this.phone,
            channelCode: this.channelCode,
            loginType: this.loginType,
            deviceId: getCookie("deviceId") || "",
            path: process.env.CHANNEL || "",
          });
          if (res && res.code === 108) {
            this.needRegister = true;
            this.startCountDown();
          } else if (res?.code === 0) {
            this.startCountDown();
          } else {
            Toast(res?.msg || "系统异常，请稍后重试");
          }
        } catch (e) {
          Toast(`请求异常，${e}`);
        } finally {
          this.loading = false;
        }
      } else {
        Toast("手机号码不正确");
      }
    },
    async login() {
      try {
        this.loading = true;

        let res;

        switch (this.loginMode) {
          case 1:
            res = await userLogin({
              phone: this.phone,
              channelCode: this.channelCode,
              verficationCode: this.form.verficationCode,
              loginType: this.loginType,
              userName: this.form.userName,
              cityId: this.form.cityId,
            });
            break;

          case 2:
            res = await userLoginByPassword({
              channelCode: this.channelCode,
              loginType: this.loginType,
              userCode: this.form.userCode,
              password: this.form.password,
            });
            break;

          default:
        }

        if (res?.code === 0) {
          location.replace(decodeURIComponent(this.redirectUrl));
        } else if (res?.code === 911911) {
          location.replace(`${location.origin}/elk/empty`);
        } else {
          Toast(res?.msg || "系统异常，请稍后重试");
        }
      } catch (e) {
        console.log("login err", e);
      } finally {
        this.loading = false;
      }
    },

    startCountDown() {
      this.isCountDown = true;
      this.startTime = +Date.now();
      this.animation();
    },
    animation() {
      const now = +Date.now();
      const countdown = COUNT_DOWN_NUM - ~~((now - this.startTime) / 1000);
      if (countdown <= 0) {
        this.isCountDown = false;
        this.countDownNum = COUNT_DOWN_NUM;
      } else {
        this.countDownNum = countdown;
        requestAnimationFrame(this.animation);
      }
    },
    async getCityOptions() {
      try {
        const data = await queryCityList();
        this.cityOptions = data || [];
      } catch (error) {
        console.error(error);
      }
    },
    onClickCityInput() {
      this.showCitySelector = true;
    },
    onFinishCity({ selectedOptions }) {
      // 选完最后一级时，关闭popup
      this.showCitySelector = false;
      this.form.city = (selectedOptions || [])
        .map((option) => option.text)
        .join("/");
    },
    switchLoginMode(mode) {
      switch (mode) {
        case 1:
          this.form.userCode = "";
          this.form.password = "";
          break;

        case 2:
          this.phone = "";
          this.form.verficationCode = "";
          this.showPassword = false;
          break;

        default:
          return;
      }

      this.loginMode = mode;
    },
  },
};
</script>

<style lang="less" scoped>
.login-page {
  padding: 12px;
  font-size: 14px;

  .page-title {
    padding: 20px;
    margin-bottom: 20px;
    font-size: 22px;
    font-weight: bold;
    color: #333;
  }

  .flex-center {
    display: flex;
    align-items: center;
  }

  .step {
    padding: 0 10px;
  }

  .complete-info {
    position: relative;
    display: flex;
    justify-content: center;
    padding: 8px 0;
    margin-top: 30px;

    .split {
      position: absolute;
      top: 50%;
      left: 0;
      display: block;
      width: 100%;
      content: "";
      border-top: 2px dashed #666;
    }

    .txt {
      position: relative;
      z-index: 1;
      display: inline-block;
      padding: 0 10px;
      font-size: 14px;
      color: #c71585;
      text-align: center;
      background: #fff;
    }
  }
  .btn-wrap {
    padding: 40px 20px 0;
  }
  .verify-code {
    display: inline-block;
    width: 100px;
    color: #4889f7;
    text-align: right;
    cursor: pointer;
  }

  .verify-code-count {
    color: #999;
  }

  .login-mode {
    margin-top: 20px;
    color: #4889f7;
  }
}
</style>
